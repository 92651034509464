var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col items-center justify-end pb-20 text-center w-full col-span-1 md:col-span-10"},[(_vm.allowed)?_c('tabs-component-router',{attrs:{"beforeRoute":'psychologist/summary',"afterRoute":"" + (_vm.$route.params.user_id) +
        (_vm.$route.query.block ? ("?block=" + (_vm.$route.query.block)) : ''),"tabs":[
      {
        key: 'clinic',
        label: 'ficha clínica',
      },
      {
        key: 'data',
        label: 'datos del paciente',
      },
      {
        key: 'form',
        label: 'primera consulta',
      } ]}}):_vm._e(),(_vm.allowed)?_c('router-view'):_vm._e(),(_vm.allowed === null)?_c('div',{staticClass:"flex justify-center items-center h-80"},[_c('svg',{staticClass:"animate-spin -ml-1 mr-3 h-10 w-10 text-gray-900",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24"}},[_c('circle',{staticClass:"opacity-25",attrs:{"cx":"12","cy":"12","r":"10","stroke":"currentColor","stroke-width":"4"}}),_c('path',{staticClass:"opacity-75",attrs:{"fill":"currentColor","d":"M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"}})]),_c('span',[_vm._v("Cargando")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }