<template>
  <div
    class="flex flex-col items-center justify-end pb-20 text-center w-full col-span-1 md:col-span-10"
  >
    <tabs-component-router
      v-if="allowed"
      :beforeRoute="'psychologist/summary'"
      :afterRoute="
        `${$route.params.user_id}` +
          ($route.query.block ? `?block=${$route.query.block}` : '')
      "
      :tabs="[
        {
          key: 'clinic',
          label: 'ficha clínica',
        },
        {
          key: 'data',
          label: 'datos del paciente',
        },
        {
          key: 'form',
          label: 'primera consulta',
        },
      ]"
    />
    <router-view v-if="allowed" />
    <div v-if="allowed === null" class="flex justify-center items-center h-80">
      <svg
        class="animate-spin -ml-1 mr-3 h-10 w-10 text-gray-900"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
      <span>Cargando</span>
    </div>
  </div>
</template>

<script>
import UserService from '@/services/userService';
import TabsRouter from '@/components/navigation/TabsRouter';

export default {
  name: 'Summary',
  components: {
    'tabs-component-router': TabsRouter,
  },
  data() {
    return {
      allowed: null,
    };
  },
  created() {
    this.check(this.$route.params.user_id);
  },
  methods: {
    check(id) {
      this.allowed = null;
      UserService.patientOwnership(id)
        .then(response => {
          if (response.data.status) {
            this.allowed = true;
          } else {
            this.allowed = false;
            this.$router.replace('/home');
          }
        })
        .catch(() => {
          this.allowed = false;
          this.$router.replace('/home');
        });
    },
  },
  watch: {
    '$route.params.user_id'() {
      this.check(this.$route.params.user_id);
    },
  },
};
</script>
